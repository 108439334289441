import React from 'react';

import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import TextLayoutContainer from 'components/TextLayoutContainer/TextLayoutContainer';

const AsfaleiaPages = () => (
  <Layout>
    <Seo title="Ασφάλεια συναλλαγών" />
    <Container style={{ paddingBottom: '4rem' }}>
      <TextLayoutContainer>
        <h1>Ασφάλεια συναλλαγών</h1>
        <p>
          Το πρωτόκολλο SSL (Secure Sockets Layer), είναι σήμερα το παγκόσμιο
          standard στο διαδίκτυο, για την πιστοποίηση διαδικτυακών τόπων
          (websites) στους διαδικτυακούς χρήστες και για την κρυπτογράφηση
          στοιχείων μεταξύ των διαδικτυακών χρηστών και των διαδικτυακών
          εξυπηρετητών (web servers). Μία κρυπτογραφημένη SSL επικοινωνία
          απαιτεί όλες οι πληροφορίες, που αποστέλλονται μεταξύ ενός πελάτη και
          ενός εξυπηρετητή (server) να κρυπτογραφούνται από το λογισμικό
          αποστολής και να αποκρυπτογραφούνται από το λογισμικό αποδοχής,
          προστατεύοντας έτσι προσωπικές πληροφορίες κατά τη μεταφορά τους.
          Επιπλέον, όλες οι πληροφορίες που αποστέλλονται με το πρωτόκολλο SSL,
          προστατεύονται από έναν μηχανισμό, που αυτόματα εξακριβώνει εάν τα
          δεδομένα έχουν αλλαχτεί κατά την μεταφορά.
        </p>
        <p>
          Επιπλέον, προσωπική ασφάλεια αποτελεί το password που δίνετε όταν
          γίνεστε μέλος του{' '}
          <a href="http://www.minoera.com/">www.minoera.com</a>. Για να
          παρουσιάσουμε οποιοδήποτε προσωπικό σας στοιχείο θα πρέπει πρώτα να
          δοθεί το όνομα του χρήστη και το password. Για αυτό το λόγο, πρέπει να
          φυλάσσετε καλά αυτά τα στοιχεία, για να μην κινδυνεύσουν να πέσουν σε
          χέρια τρίτων. Επίσης σας συμβουλεύουμε να δημιουργείτε password
          χρησιμοποιώντας σύμβολα μαζί με αλφαριθμητικούς χαρακτήρες.
        </p>
        <p>
          Τέλος να υπενθυμίσουμε ότι τα στοιχεία της πιστωτικής κάρτας δεν
          αποθηκεύονται σε αποθηκευτικά μέσα της εταιρείας κατά τη διάρκεια της
          συναλλαγής, αλλά καταχωρούνται απευθείας σε ασφαλές περιβάλλον της
          συνεργαζόμενης εταιρείας (Alpha e-Commerce της Alpha Bank) που έχει
          αναλάβει τη δρομολόγηση των καρτών.
        </p>
        <p>
          Όλες οι συναλλαγές που πραγματοποιείτε μέσω του{' '}
          <a href="http://www.minoera.com/">www.minoera.com </a>
          διέπονται από το Διεθνές και Ευρωπαϊκό δίκαιο, που ρυθμίζει θέματα
          σχετικά με το ηλεκτρονικό εμπόριο, καθώς επίσης και από το Νόμο περί
          προστασίας των καταναλωτών (Ν. 2251/1994), που ρυθμίζει θέματα σχετικά
          με τις πωλήσεις από απόσταση.
        </p>
      </TextLayoutContainer>
    </Container>
  </Layout>
);

export default AsfaleiaPages;
